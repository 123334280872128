import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

import comunes from './routes/comunes'
import admin from './routes/admin'
import contentmanager from './routes/content-manager'
import raices from './routes/raices'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'main-home' } },
    // ...apps,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    // ...pages,
    ...dashboard,
    ...comunes,
    ...admin,
    ...contentmanager,
    ...raices,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  const accessToken = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);
  
  const privacyAccepted = store.getters['user/getUserData'].privacy_accepted;

  if (to.path === '/termspage' && privacyAccepted) {
    next('/main-home');
  } else {
    next();
  }

  // Verificar si la ruta actual es la página de inicio de sesión
  const isLoginPage = to.name === 'auth-login';
  const isForgotPasswordPage = to.name === 'forgot-password';
  const isResetPasswordPage = to.name === 'auth-reset-password-v2';

  // Si no hay sesión iniciada y no está en la página de inicio de sesión, redirigir a la página de inicio de sesión
  if (isLoggedIn == null && !isLoginPage) {
    // console.log('No hay sesión iniciada. Redirigiendo a la página de inicio de sesión.');
    return next({ name: 'auth-login' });
  }

  // Si no ha aceptado los términos y no está en la página de términos, redirigir a la página de términos
  if (privacyAccepted === null && to.name !== 'termspage') {
    // console.log('No se han aceptado los términos. Redirigiendo a la página de términos.');
    return next({ name: 'termspage' });
  }

  if (to.meta.redirectIfLoggedIn && (isLoggedIn || isForgotPasswordPage || isResetPasswordPage)) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  // Si no esta autorizado a navegar a es pagina...
  if (!canNavigate(to)) {
    // llevar al login
    return next({ name: 'auth-login' })
  }

  // Permitir la navegación para otras condiciones
  return next();
});



// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/error-503',
    name: 'error-503',
    component: () => import('@/views/error/Error503_Manteinance.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/_sso/login',
    name: 'auth-login-sso',
    component: () => import('@/views/pages/authentication/LoginSSO.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: 'main-home',
    component: () => import('@/views/dashboard/home/MainHome.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
    },
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/user/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'Account Settings',
      action: 'read',
    },
  },
  // {
  //   path: '/user-home',
  //   name: 'user-home',
  //   component: () => import('@/views/worlds/Dash.vue'),
  //   meta: {
  //     resource: 'Web', //'WebPlatform',
  //     action: 'read',
  //   },
  // },
  {
    path: '/learning-paths',
    name: 'learning-paths',
    component: () => import('@/views/learning-paths/LearningPaths.vue'),
    meta: {
      resource: 'Learning Paths',
      action: 'read',

      /* contentRenderer: 'sidebar-left',
      contentClass: 'email-application', */
    },
  },
  {
    path: '/learning-paths/create',
    name: 'learning-paths-create',
    component: () => import('@/views/learning-paths/Create.vue'),
    meta: {
      resource: 'Learning Paths',
      action: 'read',
    },
  },
  {
    path: '/learning-paths/edit/:id',
    name: 'learning-paths-edit',
    component: () => import('@/views/learning-paths/Create.vue'),
    meta: {
      resource: 'Learning Paths',
      action: 'read',
    },
  },
  {
    path: '/learning-paths/duplicate/:id',
    name: 'learning-paths-duplicate',
    component: () => import('@/views/learning-paths/Create.vue'),
    meta: {
      resource: 'Learning Paths',
      action: 'read',
    },
  },
  {
    path: '/learning-paths/assign',
    name: 'learning-paths-assign',
    component: () => import('@/views/learning-paths/Assign.vue'),
    meta: {
      resource: 'Learning Paths',
      action: 'read',
    },
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/groups/Groups.vue'),
    meta: {
      resource: 'Groups',
      action: 'read',
    },
  },
  {
    path: '/groups/:id',
    name: 'group-view',
    component: () => import('@/views/groups/GroupView.vue'),
    meta: {
      resource: 'Groups',
      action: 'read',
    },
  },
  {
    path: '/groups/:id/edit',
    name: 'group-edit',
    component: () => import('@/views/groups/GroupEdit.vue'),
    meta: {
      resource: 'Groups',
      action: 'update',
    },
  },
  {
    path: '/dreamers',
    name: 'dreamers',
    component: () => import('@/views/dreamers/Dreamers.vue'),
    meta: {
      resource: 'Dreamers',
      action: 'read',
    },
  },
  {
    path: '/dreamers/:id',
    name: 'dreamer-view',
    component: () => import('@/views/dreamers/DreamerView.vue'),
    meta: {
      resource: 'Dreamers',
      action: 'read',
    },
  },
    {
      path: '/dreamers/:id/graphics-details',
      name: 'graphics-details',
      component: () => import('@/views/dreamers/GraphicsDetails.vue'),
      meta: {
        resource: 'Dreamers',
        action: 'read',
      },
    },
  {
    path: '/dreamers/:id/edit',
    name: 'dreamer-edit',
    component: () => import('@/views/dreamers/DreamerEdit.vue'),
    meta: {
      resource: 'Dreamers',
      action: 'update',
    },
  },
  {
    path: '/reports/create',
    name: 'reports-create',
    component: () => import('@/views/reports/CreateReport.vue'),
    meta: {
      resource: 'Reports',
      action: 'create',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/ReportsList.vue'),
    meta: {
      resource: 'Reports',
      action: 'read',
    },
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('@/views/resources/Resources.vue'),
    meta: {
      resource: 'Educational Resources',
      action: 'read',
    },
  },
  {
    path: '/resources/tutorials',
    name: 'tutorials',
    component: () => import('@/views/resources/Tutorials.vue'),
    meta: {
      resource: 'Tutorials',
      action: 'read',
    },
  },
  {
    path: '/resources/guides',
    name: 'guides',
    component: () => import('@/views/resources/Guides.vue'),
    meta: {
      resource: 'Guides',
      action: 'read',
    },
  },
  {
    path: '/resources/catalogs',
    name: 'catalogs',
    component: () => import('@/views/resources/Catalogs.vue'),
    meta: {
      resource: 'Catalogs',
      action: 'read',
    },
  },
  {
    path: '/resources/catalogs/create',
    name: 'catalogs-create',
    component: () => import('@/views/resources/CatalogForm.vue'),
    meta: {
      resource: 'Catalogs',
      action: 'read',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/help/Help.vue'),
    meta: {
      resource: 'Help',
      action: 'read',
    },
  },
  {
    path: '/faq/:category/:id/:slug',
    name: 'faq-question',
    component: () => import('@/views/help/FaqQuestion.vue'),
    meta: {
      resource: 'Help',
      action: 'read',
    },
  },
  {
    path: '/school-structure',
    name: 'school-structure',
    component: () => import('@/views/schools/Delete.vue'),
    meta: {
      resource: 'School Structure',
      action: 'delete',
    },
  },
  {
    path: '/virtual-catalog-learning-paths',
    name: 'vc-learning-paths',
    component: () => import('@/views/virtual-catalog/LearningPaths.vue'),
    meta: {
      resource: 'Virtual LP Catalog',
      action: 'read',
    },
  },
  {
    path: '/virtual-catalog-contents',
    name: 'vc-contents',
    component: () => import('@/views/virtual-catalog/Contents.vue'),
    meta: {
      resource: 'Virtual Content Catalog',
      action: 'read',
    },
  },
  {
    path: '/termspage',
    name: 'termspage',
    component: () => import('@/views/pages/authentication/components/TermsPage.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]